<template>
  <el-dialog
      :title="params.title"
      :model-value="params.show"
      @close="onCacel"
      width="800px" center>
    <el-form :model="form" :rules="rules" ref="form" label-width="120px" class="ruleForm">
      <div style="display: grid; grid-template-columns: repeat(2, 50%);" >

        <el-form-item label="客户名称" prop=" compName">
         <el-input v-model.trim="form.compName" placeholder="请输入客户名称"></el-input>
        </el-form-item>

        <el-form-item label="联系人姓名" prop="contactPerson">
         <el-input v-model.trim="form.contactPerson" placeholder="请输入联系人姓名"></el-input>
        </el-form-item>

        <el-form-item label="联系电话" prop="contactPhone">
         <el-input v-model.trim="form.contactPhone" placeholder="请输入联系电话"></el-input>
        </el-form-item>

        <el-form-item label="客户地址" prop="compAddress">
         <el-input v-model.trim="form.compAddress" placeholder="请输入客户地址"></el-input>
        </el-form-item>
        <el-form-item label="搜索位置">
          <el-input
              v-model="addressKeyword"
              placeholder="请输入地址来直接查找相关位置"
          >
          </el-input>
          <el-button
              size="small "
              type="success"
              @click="setPlace(this.addressKeyword)"
          >搜索
          </el-button>
          <div
              id="searchResultPanel"
              style="border:1px solid #C0C0C0;width:150px;height:auto; display:none;"
          ></div>
          <div style="height:480px;width: 200%">
            <div id="container" style="width: 100%;height: 400px"></div>
          </div>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" @click="submitForm('form')" >保存</el-button>
        <el-button @click="onCacel()">返回</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import axios from '@/utils/axios'
import {ElMessage} from 'element-plus'
import {number} from "echarts";
// import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
// import BmView from 'vue-baidu-map/components/map/MapView.vue'
// import BmLocalSearch from 'vue-baidu-map/components/search/LocalSearch.vue'


export default {
  name: "Form",
  // 继承父窗体列表页的数据
  props: {
    params: Object
  },
  data() {
    return {

      markerObj: null,
      map: null,
      showMap: false,
      lng: 0.0,
      lat: 0.0,
      addressKeyword: "",
      // 全局变量，用于获得配置信息等
      global: window.global,

      // 更多选项
      option: {
        
      },
      // 验证选项
      rules: {
        
        compId: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
         compName: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        contactPerson: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        contactPhone: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'},
          {pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '手机号码格式不正确',  trigger: 'change'}
        ],
        compAddress: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
      },
    };
  },
  components: {
    
  },
  computed: {
    // 由于子组件不能修改继承来的对象，所以重新复制一份
    form: function () {
      return this.params.data
    }
  },
  created() {

  },
  // 父组件中继承过来的事件
  emits: ["onClose","getTableData"],
  methods: {
    // 启动地图  ----------------121.54019847316344----------------------38.86825461399343
    mapFunc() {
	console.log('.1111111')
      this.markerObj = []
      var num = 0
      var lng = 121.54019847316344;
      var lat = 38.86825461399343;
      var title = "故宫博物院";
      // if (
      //     this.lat != null &&
      //     this.lat != undefined &&
      //     (this.lng != null) & (this.lng != undefined) &&
      //     this.lng != "" &&
      //     this.lat != ""
      // ) {
      //   lat = this.lat;
      //   lng = this.lng;
      //   title = this.addressKeyword;
      // }
      this.map = new BMapGL.Map("container");
      var point = new BMapGL.Point(lng, lat);
      this.map.centerAndZoom(point, 15);
      this.map.enableScrollWheelZoom(true);
      // 创建点标记
      // var marker = new BMapGL.Marker(point);
      // this.map.addOverlay(marker);
      // 解析地址
      var geoc = new BMapGL.Geocoder();
      // // 创建信息窗口
      var opts = {
        width: 200,
        height: 70,
      };
      // https://api.map.baidu.com/images/blank.gif
      // var infoWindow = new BMapGL.InfoWindow(title, opts);
      // // 点标记添加点击事件
      // marker.addEventListener("click", function() {
      //   this.map.openInfoWindow(infoWindow, point); // 开启信息窗口
      //   console.log(".getPosition()", marker.getPosition());
      // });

      var scaleCtrl = new BMapGL.ScaleControl(); // 添加比例尺控件
      this.map.addControl(scaleCtrl);
      var zoomCtrl = new BMapGL.ZoomControl(); // 添加缩放控件
      this.map.addControl(zoomCtrl);

      var that = this
      // 地图点击事件click。右键是rightclick。随意更换  markerObj是所有点的集合，取最后一个点的方法在 提交方法
      this.map.addEventListener('rightclick', function (e) {
        //清除地图上所有的覆盖物
        that.map.clearOverlays();
		console.log("...............",e)
        var pt = e.latlng;
        var marker = new BMapGL.Marker(new BMapGL.Point(pt.lng, pt.lat));
        that.map.addOverlay(marker);
        geoc.getLocation(pt, function (rs) {
          var content = rs.address
          var infoWindow = new BMapGL.InfoWindow(content, opts);
          that.map.openInfoWindow(infoWindow, pt); // 开启信息窗口
        })
        // // num++
        // var marNum = 'marker' + num
        // var point = new BMapGL.Point(e.latlng.lng, e.latlng.lat)
        // marNum = new BMapGL.Marker(point);
        that.markerObj.unshift(marker)
		// console.log("markerObj[011111",markerObj[0]);
		that.form.longitude = e.latlng.lng
		that.form.latitude = e.latlng.lat
		console.log("66666666666666666",that.markerObj[0].getPosition())
        // that.map.addOverlay(marNum)
      });
    },
	
	
    //输入地址查询
    setPlace(value) {
      var that = this;
      //this.map.centerAndZoom(value,15);
      var local = new BMapGL.LocalSearch(that.map, {
        renderOptions: {map: that.map},
      }); // 用城市名设置地图中心点
      local.search(value, 15);
      //创建地址解析器实例
      var myGeo = new BMapGL.Geocoder();
      // 将地址解析结果显示在地图上，并调整地图视野
      myGeo.getPoint(value, function (point) {
        that.lng = point.lng;
        that.lat = point.lat;
        that.addressKeyword = value;
		
		that.form.longitude = point.lng;
		that.form.latitude = point.lat;
        console.log(
            "----------------" +
            that.lng +
            "----------------------" +
            that.lat +
            "-----------------------" +
            that.addressKeyword
        );
      });
    },
    
    // 提交事件
    submitForm(formName) {
      var that = this

      this.$refs[formName].validate((valid) => {

        if (valid) {

          if (this.params.type == 'add') {
            axios.post('/BusinessManagement/Create', this.form).then(() => {
              ElMessage.success('添加成功')
              that.$emit('onClose')
              that.$emit('getTableData')
            })
          } else {
            axios.post('/BusinessManagement/Modify?id='+this.form.id, this.form).then(() => {
              ElMessage.success('修改成功')
              that.$emit('onClose')
              that.$emit('getTableData')
            })
          }
        }
      })
    },
    // 返回事件
    onCacel() {
      this.$emit('onClose')
    }
  }
}
</script>
<style>
	#container{
		width: 100%;
		height: 400px;
	}
</style>